import React, { useEffect, useRef } from 'react';
import './App.css';

function App() {
  const starContainerRef = useRef(null);

  useEffect(() => {
    const createStars = () => {
      const container = starContainerRef.current;
      if (!container) return;

      const numStars = 2500; // Liczba gwiazdek
      for (let i = 0; i < numStars; i++) {
        const star = document.createElement('div');
        star.classList.add('star');

        // Losowy rozmiar gwiazdek
        const size = `${Math.random() * 2 + 1}px`;
        star.style.width = size;
        star.style.height = size;

        // Losowa pozycja startowa
        star.style.top = `${Math.random() * 100}vh`;
        star.style.left = `${Math.random() * 100}vw`;

        // Ustawienie animacji dla gwiazdki
        star.style.animation = `subtleMove ${Math.random() * 5 + 5}s ease-in-out infinite`;

        container.appendChild(star);
      }
    };
    
    const startOneko = () => {
      fetch("https://raw.githubusercontent.com/adryd325/oneko.js/8fa8a1864aa71cd7a794d58bc139e755e96a236c/oneko.js")
        .then((response) => response.text())
        .then((scriptContent) =>
          scriptContent
            .replace("./oneko.gif", "https://raw.githubusercontent.com/adryd325/oneko.js/14bab15a755d0e35cd4ae19c931d96d306f99f42/oneko.gif")
            .replace("(isReducedMotion)", "(false)")
        )
        .then((modifiedScript) => eval(modifiedScript));
    };

    startOneko();

    createStars();
  }, []);

  return (
    <div className="gradient-background">
      <div className="star-field" ref={starContainerRef}></div>
      <div className="text-container">
        <h1 className="centered-text">SKYT</h1>
      </div>
    </div>
  );
}

export default App;
